const PriceService = {

    prices: 
        [
            { id: 1, mod: "B-5", pesoKGM:"0.035", pack:'Rollo', medida:"100 mts", empaque:"1 Rollo", pesoKG:"3.5", precioLineal:0.17, precioEmpaque: 16.8},
            { id: 2, mod: "B-6", pesoKGM:"0.075", pack:'Rollo', medida:"100 mts", empaque:"1 Rollo", pesoKG:"7,5", precioLineal:0.34, precioEmpaque: 34.4},
            { id: 3, mod: "B-6", pesoKGM:"0.075", pack:'Rollo', medida:"200 mts", empaque:"1 Rollo", pesoKG:"15", precioLineal:0.34, precioEmpaque: 68.8},
            { id: 4, mod: "B-8", pesoKGM:"0.12", pack:'Rollo', medida:"100 mts", empaque:"1 Rollo", pesoKG:"12", precioLineal:0.62, precioEmpaque: 61.6},
            { id: 5, mod: "B-10", pesoKGM:"0.15", pack:'Rollo', medida:"100 mts", empaque:"1 Rollo", pesoKG:"15", precioLineal:0.96, precioEmpaque: 96},
            { id: 6, mod: "B-12", pesoKGM:"0.23", pack:'Rollo', medida:"100 mts", empaque:"1 Rollo", pesoKG:"23", precioLineal:1.38, precioEmpaque: 138.4},
            { id: 7, mod: "B-8", pesoKGM:"0.12", pack:'Barra', medida:"6 mts", empaque:"30 Barras", pesoKG:"21.6", precioLineal:0.62, precioEmpaque: 110.9},
            { id: 8, mod: "B-10", pesoKGM:"0.15", pack:'Barra', medida:"6 mts", empaque:"25 Barras", pesoKG:"22.5", precioLineal:0.96, precioEmpaque: 144},
            { id: 9, mod: "B-12", pesoKGM:"0.23", pack:'Barra', medida:"6 mts", empaque:"18 Barras", pesoKG:"24.8", precioLineal:1.38, precioEmpaque: 149.5},
            { id: 10, mod: "B-16", pesoKGM:"0.41", pack:'Barra', medida:"6 mts", empaque:"10 Barras", pesoKG:"24.6", precioLineal:2.46, precioEmpaque: 147.8},
            { id: 11, mod: "B-20", pesoKGM:"0.59", pack:'Barra', medida:"6 mts", empaque:"7 Barras", pesoKG:"24.7", precioLineal:3.86, precioEmpaque: 161.9},
            { id: 12, mod: "B-25", pesoKGM:"1.06", pack:'Barra', medida:"6 mts", empaque:"4 Barras", pesoKG:"25.4", precioLineal:6.01, precioEmpaque: 144.2},
            // { id: 12, mod: "B-32", pesoKGM:"1.02", pack:'Barra', medida:"6 mts", empaque:"4 Barras", pesoKG:"24.5", precioLineal:12.31, precioEmpaque: 295.4},
        ],

    pricesPins:
        [
            { id: 1, mod: "B-20", diametro:"20", largo:'40', empaque:"50 unidades", pesoKG: '11.8', precioLineal:9.65, precioEmpaque: 193},
            { id: 2, mod: "B-20", diametro:"20", largo:'50', empaque:"50 unidades", pesoKG: '14.75', precioLineal:9.65, precioEmpaque: 241.25},
            { id: 3, mod: "B-20", diametro:"20", largo:'60', empaque:"50 unidades", pesoKG: '17.7', precioLineal:9.65, precioEmpaque: 289.5},
            { id: 4, mod: "B-25", diametro:"25", largo:'40', empaque:"50 unidades", pesoKG: '21.2', precioLineal:15.1, precioEmpaque: 302},
            { id: 5, mod: "B-25", diametro:"25", largo:'50', empaque:"40 unidades", pesoKG: '21.2', precioLineal:15.1, precioEmpaque: 377.5},
            { id: 6, mod: "B-25", diametro:"25", largo:'60', empaque:"40 unidades", pesoKG: '25.4', precioLineal:15.1, precioEmpaque: 453},
        ],
    
    // pricesSeparator:
    //     [
    //         { id: 1, mod: "B-20", diametro:"20", empaque:"XXX", pesoKG: 'XXX', precioLineal:'XXX', precioEmpaque: 'XXX'},
    //         { id: 2, mod: "B-25", diametro:"25", empaque:"XXX", pesoKG: 'XXX', precioLineal:'XXX', precioEmpaque: 'XXX'},
    //     ]
}

export default PriceService